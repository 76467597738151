<template>
  <div class="app-container" v-loading="loading">
    <div class="top_box">
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <el-form-item label="用户ID">
          <el-input
            v-model="queryParams.id"
            placeholder="用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input
            v-model="queryParams.username"
            placeholder="用户名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="权限">
          <el-select
            v-model="queryParams.is_staff"
            placeholder="用户OR管理员"
            clearable
          >
            <el-option label="管理员" value="true"></el-option>
            <el-option label="用户" value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="搜索">
          <el-input
            v-model="queryParams.search"
            placeholder="用户名、备注等..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          :data="tableData"
          style="width: 100%"
          fit
          border
          highlight-current-row
          class="tableList3"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          algin="center"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>

          <el-table-column prop="points" label="积分"></el-table-column>
          <el-table-column prop="roles" label="权限"></el-table-column>

          <el-table-column prop="note" label="备注"></el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="scope">
              <!-- 修改积分 -->
              <el-button
                @click.native.prevent="editPoints(scope.row.id)"
                type="text"
                size="small"
              >
                修改积分
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.page_index"
          :page-size="queryParams.page_size"
          :page-sizes="[queryParams.page_size, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="showPointsDialog">
      <Points
        v-if="showPointsDialog"
        :userId.sync="editUserId"
        @pointsDialog="pointsDialogValue"
      ></Points>
    </el-dialog>

    <!-- 添加用户的from 开始-->
    <!-- <el-dialog title="添加用户" :visible.sync="showUserForm" width="30%">
      <userForm @initPage="pageInit"></userForm>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog> -->
    <!--添加用户的form 结束 -->
  </div>
</template>

<script>
import { userList, updateUser } from '@/api/user'
import Points from './components/points'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'userManage',
  mixins: [cacheMixin],
  components: { Points },
  data() {
    return {
      tableData: [],
      queryParams: {
        id: null,
        username: null,
        is_staff: null,
        search: null,
        page_index: 1,
        page_size: 10
      },
      total: 0,
      editUserId: null,
      showPointsDialog: false,
      loading: false
    }
  },
  created() {
    this.pageInit()
  },
  methods: {
    pageInit() {
      this.queryData()
    },
    queryData() {
      this.loading = true
      userList(
        // Object.assign(
        //   { pageIndex: this.page, pageSize: this.limit },
        //   this.searchUser
        // )
        this.queryParams
      ).then(resp => {
        this.tableData = resp.items
        this.total = resp.count
        this.loading = false
      })
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.queryParams.page_size = val
      this.queryData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.queryParams.page_index = val
      this.queryData()
    },

    onSearch() {
      this.page = 1
      this.queryData()
    },
    editPoints(id) {
      // 分配账号
      this.editUserId = id
      this.showPointsDialog = true
    },
    async saveEdit(accountId, data) {
      // row.edit = false
      await updateUser(accountId, data)
      this.queryData()
    },
    // 积分弹窗关闭
    pointsDialogValue(data) {
      this.showPointsDialog = data
      this.queryData()
    }
  }
  // watch: {
  //   showPointsDialog: {
  //     immediate: true,
  //     handler(value) {
  //       if (value === false) {
  //         this.queryData()
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  button.el-button.el-button--success.el-button--mini {
    height: 32px;
    padding: 0 20px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 50px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
.leftrow {
  margin-left: 0;
}
</style>
